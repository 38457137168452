<template>
  <Card class="card">
    <template #header>
      <img :src="pet.image" />
    </template>
    <template #title>
      {{ pet.name }}
    </template>

    <template #subtitle>
      <h3>Age : {{ pet.age }}</h3>
      <h3>Gender : {{ pet.gender }}</h3>
      <h3>Breed: {{ pet.breed }}</h3>
    </template>

    <template #footer >
      <div class="round-icon-wrapper">
      <div class="round-icon">
        <Button
          @click="$emit('vaccinated-pet', pet.id)"
          icon="pi pi-check"
          :class="[
            pet.vaccinated ? '' : 'p-button-outlined',
            'p-button-rounded',
            'p-button-success',
          ]"
        />

        <h3>Vaccinated</h3>
      </div>
     </div>

      <Button
        @click="onRemove(pet.id)"
        icon="pi pi-times"
        label="Remove"
        class="p-button-danger"
        style="margin-right: 4em; width: 100%"
      />
    </template>
  </Card>
</template>

<script>
export default {
  props: ["pet"],

  methods: {
    onRemove(id) {
      this.$emit("remove-pet", id);
    },
  },
};
</script>

<style>
img {
  height: 300px;
  object-fit: cover;
  border: .7rem solid rgba(205, 184, 184, 0.629);
}
.p-card-content {
  display: none !important;
}
.round-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top:-1rem ;
}
.round-icon-wrapper {
  margin-top:-1rem;
  display: flex;
  justify-content: center;
}
.card{
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.45) !important;
-moz-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.45)!important;
box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.45)!important;
border: solid 1px black;
}
</style>
