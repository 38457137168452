<template>
  <div class="home">
    <PetAdd @pet-add="petAdd" />

    <PetFilter
      v-on:filtered="updatePets"
      :pets="pets"
      @allPets="showAllPets"
    ></PetFilter>
    <!-- new Component for the FilterFunction -->
    <Pets
      @remove-pet="removePet"
      @vaccinated-pet="vaccinatedPet"
      v-bind:pets="showPets"
    />
  </div>
</template>

<script>
import Pets from "@/components/Pets.vue";
import PetAdd from "@/components/PetAdd.vue";
import PetFilter from "@/components/PetFilter.vue";
export default {
  name: "HomeView",
  components: {
    Pets,
    PetAdd,
    PetFilter,
  },
  data() {
    return {
      pets: [],
      filteredPets: null, // added filtered Pets variable
    };
  },
  methods: {
    updatePets(filteredPets) {
      // added emit function
      this.filteredPets = filteredPets;
      if (filteredPets.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is no Pet for this criteria!",
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
      // console.log(filteredPets);
    },
    async petAdd(pet) {
      const response = await fetch(
        "https://63be9380f5cfc0949b5b131a.mockapi.io/pets",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },

          body: JSON.stringify(pet),
        }
      );
      const data = await response.json();
      this.pets = [data, ...this.pets];

      Swal.fire({
        // position: "top-end",
        icon: "success",
        title: "New pet successsfully saved to data",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    async removePet(id) {
      {
        const response = await fetch(
          `https://63be9380f5cfc0949b5b131a.mockapi.io/pets/${id}`,
          {
            method: "DELETE",
          }
        );
        response.status === 200;
      }

      if (
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.pets = this.pets.filter((pet) => pet.id !== id);
            Swal.fire("Deleted!", "Pet has been deleted.", "success");
          }
        })
      );
    },

    async vaccinatedPet(id) {
      const vaccinated = await this.fetchPet(id);
      const updatedVaccinated = {
        ...vaccinated,
        vaccinated: !vaccinated.vaccinated,
      };
      const response = await fetch(
        `https://63be9380f5cfc0949b5b131a.mockapi.io/pets/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(updatedVaccinated),
        }
      );
      const data = await response.json();
      this.pets = this.pets.map((pet) =>
        pet.id === id ? { ...pet, vaccinated: data.vaccinated } : pet
      );
    },

    async fetchPets() {
      const response = await fetch(
        "https://63be9380f5cfc0949b5b131a.mockapi.io/pets"
      );
      const data = await response.json();
      return data;
    },
    async fetchPet(id) {
      const response = await fetch(
        `https://63be9380f5cfc0949b5b131a.mockapi.io/pets/${id}`
      );
      const data = await response.json();
      return data;
    },
    showAllPets() {
      this.filteredPets = null;
    },
  },

  computed: {
    showPets() {
      return this.filteredPets === null ? this.pets : this.filteredPets; // function to show all pets if no filter is selected
    },
  },
  async created() {
    this.pets = await this.fetchPets();
  },
};
</script>
<style></style>
