import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import store from './store'
import { auth } from "./firebase";


import Button from "primevue/button";
import Card from "primevue/card";
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Menubar from 'primevue/menubar';

import Swal from 'sweetalert2'

window.Swal = Swal;


import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import '/node_modules/primeflex/primeflex.css'

const app = createApp(App)
app.use(router);
app.use(store);
app.use(PrimeVue, {ripple: true});
app.component("Button", Button);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("RadioButton", RadioButton);
app.component("Menubar", Menubar);



app.mount('#app');



auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .mount("#app");
  }
});