<template>
  	
		<div>
			<Menubar :model="items">
				<template #start>
					<div id="nav" v-if="$store.state.user">
            <Button><router-link style="color:white" to="/home">Home </router-link></Button>
            <Button style="margin-left: 0.5rem;"><router-link style="color:white"  to="/about"> About </router-link></Button>
      
      
     
      
    </div>
				</template>
				<template #end>
          <div class="p-inputgroup">
        <Button v-if="$store.state.user" @click="$store.dispatch('logout')" style="width: 100%" type="submit" label="Logout" />
           <a href="https://ertugrulkok.com/"
      > <Button style="width: 100% ; margin-left: 0.2rem" type="submit" label="Main" />
    </a>
      </div>
      
				</template>
			</Menubar>
		</div>



  <div class="main"><router-view /></div>
</template>

<script>
import { onBeforeMount } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch("fetchUser");
    });
  },
};
</script>

<style>
body {
  margin: 0;
}
.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {text-decoration: none;}

/* nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2c3e50;
  color: white;
  padding: 20px 50px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 4rem;
} */

/* nav a {
  font-size: 40px;
  color: white;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #42b983;
}
nav .main-page-link {
  font-size: 20px;
} */
/* .logoutButton{
  background-color: #42b983;
  padding: 0.7rem;
  color: white;
  cursor: pointer;
  border-radius: 0.4rem;
  font-size:1rem;

}
.logoutButton:hover{
  opacity: 50;
} */
</style>
