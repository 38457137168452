<template>
<h1 style="margin-top:3rem">Search for a Pet</h1>
  <form
    @submit="onSubmit"
    class="grid p-fluid"
    style="margin-bottom: 2rem; margin-top: rem; border:solid 1px black; justify-content:space-around"
  >

    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="name" class="p-sr-only"></label>
        <InputText
        v-model="filterName"
          id="name"
          type="text"
          placeholder="Pet Name"
        />
      </div>
    </div>
    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="age" class="p-sr-only"></label>
        <InputText v-model="filterAge" id="age" type="number" placeholder="Pet Age" />
      </div>
    </div>


    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="breed" class="p-sr-only"></label>
        <InputText
          v-model="filterBreed"
          id="age"
          type="text"
          placeholder="Pet Breed"
        />
      </div>
    </div>

    <div class="col-12 md:col-1">
      
      <div class="field-radiobutton" style="margin-top:0.6rem">
        <RadioButton
          inputId="city1"
          name="city"
          value="Male"
          v-model="filterGender"
        />
        <label for="city1">Male</label>
      </div>
  
  </div>

  <div class="col-12 md:col-1">
   
      <div class="field-radiobutton" style="margin-top:0.6rem">
        <RadioButton
          inputId="city2"
          name="city"
          value="Female"
          v-model="filterGender"
        />
        <label for="city2">Female</label>
      </div>
  
  </div>

    <div class="col-12 md:col-2">
      <div class="p-inputgroup" >
        <Button @click="emitFilterEvent" style="width:100%"  label="Search" />
      </div>
    </div>
    <div class="col-12 md:col-2">
      <div class="p-inputgroup" >
        <Button @click="allPets" style="width:100%"  label="All Pets" />
      </div>
    </div>
   
  </form>



  <!-- <div>
    <label>Name: <input v-model="filterName" /></label>
    <label>Age: <input v-model="filterAge" /></label>
    <label>Gender: <input v-model="filterGender" /></label>
    <button @click="emitFilterEvent">Filter</button>
  </div> -->
</template>

<script>
export default {
  props: ["pets"],
  // you can add more FilterValues here -> add them also in the filteredPets function 
  data() {
    return {
      filterName: "",
      filterAge: "",
      filterGender: "",
      filterBreed:"",
    };
  },
  computed: {
    filteredPets() { 

      if (!Object.values(this.$data).some(val => val !== "")) {
        return []
      }


      return this.pets.filter((pet) => {
        // console.log(this.pets)
                return (
          pet.name.toLowerCase().includes(this.filterName.toLowerCase()) &&
          (this.filterAge === "" || pet.age == this.filterAge) &&
          (this.filterBreed === "" || pet.breed == this.filterBreed) &&
          (this.filterGender === "" || pet.gender === this.filterGender)

        ); 
        
      });
    
    },
  },
  methods: {
    emitFilterEvent() {
      this.$emit("filtered", this.filteredPets);
      this.filterName= "",
      this.filterAge= "",
      this.filterGender= "",
      this.filterBreed=""
    },

    allPets() {
      this.$emit("allPets");
    },
  
  },
};
</script>
