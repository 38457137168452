<template>
    
  <div class="grid" style="justify-content:space-around">
 <div class="col-12 md:col-6 lg:col-3" v-for="pet in pets" :key="pet.id" >
<!-- <h3>{{pet.name}}</h3> -->
<Pet @remove-pet="$emit('remove-pet', pet.id)" @vaccinated-pet="$emit('vaccinated-pet', pet.id)" v-bind:pet="pet"/>
</div>

  </div>
</template>

<script>

import Pet from './Pet.vue';
export default {

    components:{
        Pet
    },

    props: ["pets"],
    emits: ["remove-pet", "vaccinated-pet"],
}
</script>
 
<style>
.grid{
padding-top: 1rem;

}
</style>