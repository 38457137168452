import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyB8d22iu_QTuP9Fd_04zzpz1BfmZOYItxI",
  authDomain: "pet-shelter-afb94.firebaseapp.com",
  projectId: "pet-shelter-afb94",
  storageBucket: "pet-shelter-afb94.appspot.com",
  messagingSenderId: "921301825170",
  appId: "1:921301825170:web:6235b5cd83e5fd23a4584b",
  measurementId: "G-N4BMTTZBNT"
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth }