<template>
  <h1 style="margin-top: 3rem">Add a Pet</h1>
  <form
    @submit="onSubmit"
    class="grid p-fluid"
    style="margin-bottom: 2rem; margin-top: rem; border: solid 1px black"
  >
    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="name" class="p-sr-only"></label>
        <InputText
          v-model="name"
          id="name"
          type="text"
          required=""
          placeholder="Pet Name"
        />
      </div>
    </div>
    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="age" class="p-sr-only"></label>
        <InputText v-model="age" id="age" type="number" placeholder="Pet Age" />
      </div>
    </div>

    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="breed" class="p-sr-only"></label>
        <InputText
          v-model="breed"
          id="age"
          type="text"
          required=""
          placeholder="Pet Breed"
        />
      </div>
    </div>
    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <label for="image" class="p-sr-only"></label>
        <InputText
          v-model="image"
          id="image"
          type="text"
          required=""
          placeholder="Pet Image"
        />
       </div>
    </div>
    <div class="col-12 md:col-1">
      <div class="field-radiobutton" style="margin-top: 0.6rem">
        <RadioButton
          inputId="city1"
          name="city"
          value="Male"
          v-model="gender"
          required=""
        />
        <label for="city1">Male</label>
      </div>
    </div>

    <div class="col-12 md:col-1">
      <div class="field-radiobutton" style="margin-top: 0.6rem">
        <RadioButton
          inputId="city2"
          name="city"
          value="Female"
          v-model="gender"
          required=""
        />
        <label for="city2">Female</label>
      </div>
    </div>

    <div class="col-12 md:col-2">
      <div class="p-inputgroup">
        <Button style="width: 100%" type="submit" label="Save" />
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "PetAdd",
  data() {
    return {
      name: "",
      age: "",
      gender: "",
      breed: "",
      image: "",
      vaccinated: false,
      sex: null,
      gender: [{ gender: "Male" }, { gender: "Female" }],
    };
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();

      const newPet = {
        name: this.name,
        age: this.age,
        gender: this.gender,
        breed: this.breed,
        image: this.image,
        vaccinated: true,
      };

      this.$emit("pet-add", newPet);

      this.name = "";
      this.age = "";
      this.gender = "";
      this.breed = "";
      this.image = "";
    },
  },
};
</script>

<style></style>
